
import SupportMessage from "@/components/SupportMessage"
import { useAccountStore } from "@/stores/account"
import { useCameraStore } from "@/stores/camera"
import { camelizeKeys } from "humps"

export default {
  name: "NotFound",
  components: { SupportMessage },
  async asyncData({ redirect, route }) {
    const routeCameraExid = camelizeKeys(route.params)?.cameraExid
    const selectedCamera = useAccountStore().cameras?.find(
      (camera) => camera.id === routeCameraExid
    )
    if (selectedCamera) {
      redirect(useCameraStore().cameraRoute)
    }
  },
  head() {
    return {
      title: "Evercam | 404 Not Found",
      meta: [
        { charset: "utf-8" },
        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
}
